import React from "react";

export function RGPIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#40BAD5" />
      <path d="M17.7476 17.7032H14.2516L11.9275 14.2466L15.3398 14.3155L17.7476 17.7032Z" fill="white" />
      <path
        d="M17.7478 10.7161C17.7478 11.8683 17.28 12.9122 16.5267 13.6656C15.7733 14.419 14.7294 14.8867 13.5772 14.8867H12.3708L10.4456 12.1392H13.5477C13.9367 12.1392 14.2912 11.9816 14.5472 11.7255C14.8033 11.4695 14.9608 11.115 14.9608 10.726C14.9608 9.94307 14.3257 9.31281 13.5477 9.31281H11.8686L10.0024 6.6588L9.92365 6.55047H13.5674C15.8816 6.54555 17.7478 8.41173 17.7478 10.7161Z"
        fill="white"
      />
      <path d="M12.1835 10.7013H11.6911L8.78107 6.54548L9.27839 6.55041L12.1835 10.7013Z" fill="white" />
      <path d="M9.94792 10.7013H9.4506L6.54547 6.54551L7.04279 6.55044L9.94792 10.7013Z" fill="white" />
      <path d="M11.0654 10.7013H10.573L7.66296 6.54548L8.16028 6.55041L11.0654 10.7013Z" fill="white" />
    </svg>
  );
}

export function AlertSvg(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="8" fill="#0760A8" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.0004 13.7499C34.0004 13.6147 33.9638 13.482 33.8945 13.3659C33.8252 13.2498 33.7258 13.1546 33.6068 13.0903C33.4879 13.0261 33.3537 12.9952 33.2186 13.0009C33.0836 13.0066 32.9525 13.0488 32.8394 13.1229C32.7924 13.1529 32.7454 13.1799 32.6974 13.2079C32.5368 13.3002 32.3734 13.3875 32.2074 13.4699C31.7664 13.6899 31.0974 13.9889 30.2054 14.2899C28.4254 14.8899 25.7554 15.4999 22.2504 15.4999H18.5004C17.172 15.4986 15.888 15.9782 14.8856 16.85C13.8833 17.7218 13.2304 18.927 13.0475 20.2428C12.8646 21.5586 13.1642 22.8961 13.8908 24.0082C14.6174 25.1203 15.722 25.9319 17.0004 26.2929V26.7499C17.0004 29.8109 17.6844 32.2549 18.0614 33.3709C18.3014 34.0799 18.9654 34.4999 19.6614 34.4999H21.6744C22.9684 34.4999 23.7744 33.1779 23.4064 32.0469C22.9944 30.7789 22.5004 28.7789 22.5004 26.4999C22.5004 26.4699 22.4984 26.4399 22.4954 26.4119C25.8774 26.4399 28.4604 27.0559 30.1984 27.6629C31.0884 27.9749 31.7574 28.2829 32.1984 28.5119C32.2824 28.5549 32.3694 28.6079 32.4594 28.6619C32.8164 28.8759 33.2164 29.1169 33.6014 28.9119C33.7218 28.8482 33.8225 28.7529 33.8928 28.6362C33.9631 28.5196 34.0003 28.3861 34.0004 28.2499V13.7499ZM22.5004 24.9119C26.0644 24.9409 28.8134 25.5899 30.6934 26.2469C31.4304 26.5049 32.0334 26.7639 32.5004 26.9869V14.9929C32.0334 15.2089 31.4274 15.4599 30.6854 15.7109C28.8074 16.3449 26.0614 16.9709 22.5004 16.9989V24.9119ZM18.5004 26.7499V26.4999H21.0004C21.0004 28.9859 21.5374 31.1479 21.9804 32.5099C21.9975 32.5676 22.0012 32.6285 21.9914 32.6879C21.9815 32.7474 21.9583 32.8038 21.9234 32.8529C21.8534 32.9569 21.7614 32.9999 21.6744 32.9999H19.6614C19.5564 32.9999 19.5004 32.9419 19.4824 32.8909C19.1384 31.8729 18.5004 29.5969 18.5004 26.7499ZM18.5004 16.9999H21.0004V24.9999H18.5004C17.4396 24.9999 16.4222 24.5785 15.672 23.8283C14.9219 23.0782 14.5004 22.0608 14.5004 20.9999C14.5004 19.939 14.9219 18.9216 15.672 18.1715C16.4222 17.4213 17.4396 16.9999 18.5004 16.9999Z"
        fill="white"
      />
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.16937 15.5801C8.97937 15.5801 8.78938 15.5101 8.63938 15.3601C8.34938 15.0701 8.34938 14.5901 8.63938 14.3001L14.2994 8.64011C14.5894 8.35011 15.0694 8.35011 15.3594 8.64011C15.6494 8.93011 15.6494 9.41011 15.3594 9.70011L9.69937 15.3601C9.55937 15.5101 9.35937 15.5801 9.16937 15.5801Z"
        fill="white"
      />
      <path
        d="M14.8294 15.5801C14.6394 15.5801 14.4494 15.5101 14.2994 15.3601L8.63938 9.70011C8.34938 9.41011 8.34938 8.93011 8.63938 8.64011C8.92937 8.35011 9.40937 8.35011 9.69937 8.64011L15.3594 14.3001C15.6494 14.5901 15.6494 15.0701 15.3594 15.3601C15.2094 15.5101 15.0194 15.5801 14.8294 15.5801Z"
        fill="white"
      />
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
        fill="white"
      />
    </svg>
  );
}
