import { Button, useColorModeValue } from '@chakra-ui/react';
import React from 'react';


const DoneBtn = () => {
    const bgColor = useColorModeValue("#8817BB", "#5F0BA4");
    return (
        <>

            <Button background='white' variant='brand' color={bgColor}>
                Done
            </Button>
        </>
    );
};

export default DoneBtn;
